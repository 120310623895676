import React from "react";
import { Link } from "react-router-dom";
import Skyline from "./SkyLine";

export const ErrorPage = () => {
  return (
    <div className="error-section">
      <div className="error-animation">
        <Skyline />
      </div>
      <div className="error-content">
        <h1>:-(</h1>
        <p>Oops, the page you're looking for doesn't exist.</p>
        <p className="error-back">
          Please continue to our <Link to="/">Home page</Link>.
        </p>
      </div>
    </div>
  )
};
export default ErrorPage;
