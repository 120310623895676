import React, { useRef, useState, useEffect, useMemo } from "react";
import BannerVideo from "../assets/video/bg-video.mp4";
import TypedAnimation from "./TypedAnimation";
import Heighlight from "./Heighlight";
import { loadImage } from "./Const";
import { initializeWow, cleanupWow } from './Const';
import { ModalContactKeepe } from "./ModalContactKeepe";

const Banner = () => {
  const [contentIndex, setContentIndex] = useState(0); // Track which content to display
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    initializeWow();

    return () => {
      cleanupWow();
    };
  }, []);

  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const contents = useMemo(() => [
    {
      text: "Keepe’s technology platform connects your customers with our nationwide network of qualified technicians quickly and seamlessly, enabling you to focus on managing your business while we manage the logistics.",
      delay: 15700,
    },
    {
      text: "Keepe goes beyond simple matching - we deliver speedy, high quality, and cost effective fulfillment of home repair services. We manage everything related to the resident experience including timely scheduling, technician assignments, authorizations, parts ordering and final invoicing.",
      delay: 9300,
    },
    {
      text: "Our technicians are thoroughly vetted, background checked, insured, and monitored to ensure a great customer experience every time.",
      delay: 14700,
    },
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setContentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    }, contents[contentIndex].delay); // Change content based on specified delay

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [contents, contentIndex]);

  return (
    <>
      <section className="main-banner-wrap position-relative">
        <video id="background-video" loop autoPlay muted playsInline poster={loadImage("bg-poster.jpg")}>
          <source src={BannerVideo} type="video/mp4" />
        </video>
        <div className="mb-overlay"></div>
        <div className="mb-pos position-absolute start-0 w-100">
          <div className="container">
            <div
              className="title-heading text-center mb-4 wow animate__animated animate__zoomIn fixed-height-heading"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <TypedAnimation />
            </div>
            <p
              key={contentIndex}
              className="text-white text-center mxw-680 wow animate__animated animate__zoomIn sub-heading fixed-height-paragraph"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              {contents[contentIndex].text}
            </p>
            <div
              className="mt-3 text-center wow animate__animated animate__zoomIn"
              data-wow-duration="1s"
              data-wow-delay="1.5s"
            >
              <button
                className="theme-btn gradient-btn"
                type="button"
                onClick={handleShow}
              >
                Contact Keepe
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={scrollToNextSection}
          className="scroll-down arrow"
        >
          <span></span>
        </button>
      </section>
      <Heighlight ref={nextSectionRef} />
      <ModalContactKeepe
       modalIsOpen={show}
       closeModal={handleClose}
      />
    </>
  );
};
export default Banner;
