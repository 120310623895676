import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/Home";
import ContactUs from "./pages/ContactUs/ContactUs"
import ErrorPage from "./pages/ErrorPage/Error";
import About from "./pages/About/About"
import Support from "./pages/Support/Support";
import TermsAndPolicy from "./pages/TermsAndPolicy/TermsAndPolicy";
import Residents from "./pages/Residents/Residents";

const SetRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/about" element={<About />} />
      <Route path="/support" element={<Support />} />
      <Route path="/legal/us/terms" element={<TermsAndPolicy />} />
      <Route path="/legal/us/privacy" element={<TermsAndPolicy />} />
      <Route path="/legal/us/copyright" element={<TermsAndPolicy />} />
      <Route path="/residents" element={<Residents/>} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
export default SetRoutes;
