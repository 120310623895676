export const loadImage = (imageName) => {
  const image = require(`../assets/img/${imageName}`);
  return image;
};

export const testimonialsData = [
  {
    name: "N. Adjoa",
    message:
      "Excellent, excellent service by Alex. Great service...very detailed. Went to the hardware store quickly to buy a part and did an excellent job. Thank you Keepe for your scheduling and follow up, and thank you Alex. Highly recommend Keepe for any appliance service and repair 100%",
  },
  {
    name: "M Armstrong",
    message:
      "The technicians arrived early, were very efficient & completed the repairs in a timely manner. It was very easy to set my appointment through Keepe & was kept alerted to any needed information. I was very pleased in total with my experience",
  },
  {
    name: "R Cherry",
    message:
      "The technician Mr. Mohammad is out of this world great job and respectful, friendly and clean up behind yourself. He got the work done and it look good. I could not ask for a better person to work on my equipment. I request that if anything goes wrong with my equipment down the road, that he is the only person I would like to have back to my home. Thanks Keepe",
  },
  {
    name: "Althea J",
    message:
      "Marcus was the technician, from Keepe whom explained the problem, showed us the problem, and fixed the problem. He had to go to the hardware store and get the right screws. He took his time and patiently worked on the system until it was right. I'm completely satisfied with his customer service and knowledge. It's has been a long while, to solve this problem, I'm glad it's finally done.",
  },
  {
    name: "Jhonatan Rotberg",
    message:
      "The Keepe technician solved in 15 mins a seemingly intractable problem that had evaded three other plumbers before over the course of more than a year. He should be commended. Made my membership in the Sears Home Warranty worth the entire year.",
  },
  {
    name: "Javier Diaz",
    message:
      "Great! Service!! Loved the appointment system. I was able to choose the best day and time so easy and got a couple reminders that a technician was coming then received text to make sure tech arrived and completed the repair. Thanks",
  },
  {
    name: "Yolonda Eagle",
    message:
      "I'm very satisfied with the service you provided. The technician kept me informed of everything. I recommend keepe to anybody. My refrigerator is back on track now ❤️",
  },
  {
    name: "Denise Stephenson",
    message:
      "Maurice, the technician, was the best. He was calm, educational, honest and sincere about finding the problem, diagnosing it correctly and fixing it. You’ve got a great guy on your team. Thank you.",
  },
  {
    name: "Barry Thurman",
    message:
      "Had an Electrical problem with my Garage and Utility room. The Technicians came in, Friendly as Could Be and started at the Main Box and didn't Stop until they Isolated the short and put in New Hall lights. They had to Go to get the New Lights and they came back with Beautiful New Hallway Lights. Took them 5 minutes to Put up the New Lights, but the Effort put into the Whole Job was Seamless. Thanks to the Technicians. You Deserve raises.",
  },
];

// wow.js

let wowInstance;

export const initializeWow = () => {
  if (typeof window !== 'undefined' && !wowInstance) {
    const Wow = require('wow.js'); // Import Wow.js dynamically
    wowInstance = new Wow({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wowInstance.init();
  }
};

export const cleanupWow = () => {
  if (wowInstance) {
    wowInstance.stop(); // or any other cleanup method provided by Wow.js
    wowInstance = null;
  }
};




