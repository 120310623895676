import React from "react";
import { testimonialsData } from "./Const";
import Marquee from "react-fast-marquee";

const Testimonials = () => {
  return (
    <section className="overflow-hidden">
      <section
        className="testimonials-wrap py-5 wow animate__animated animate__slideInUp"
        data-wow-duration="1s"
        data-wow-delay="1s"
      >
          <div className="title-lg text-center text-black fw-700">
            What our users say
          </div>
          <Marquee>
            {testimonialsData.map((item, index)=>
              <div className="testimonial-item" key={index}>
                <div className="testimonial-box p-4">
                  <div className="testimonial-name">{item.name}</div>
                  <div className="testimonial-msg">{item.message}</div>
                </div>
              </div>
            )}
          </Marquee>
      </section>
    </section>
  );
};
export default Testimonials;
