import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useSearchParams } from 'react-router-dom';
import { initializeWow, cleanupWow } from '../../components/Const';

const Support = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [searchParams]);

    useEffect(() => {
        initializeWow();

        return () => {
            cleanupWow();
        };
    }, []);
    return (
        <div className='support-section'>
            <Header isAddbg={true} />
            <div className='support-map'>
                <iframe
                    width="100%"
                    height="450"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.167029067709!2d-122.3335818!3d47.5995348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ab06e86a863%3A0xaa25486c102f2cc6!2s901+5th+Ave+%231950%2C+Seattle%2C+WA+98164%2C+USA!5e0!3m2!1sen!2suk!4v1556922064799!5m2!1sen!2suk"
                    title='keepe-office-location'
                ></iframe>
            </div>

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div
                        className="col-md-8 wow animate__animated animate__fadeInLeft"
                        data-wow-duration="1s"
                        data-wow-delay="1s"
                    >
                        <h3 className='section-heading'>Send us a message</h3>
                        <p className='section-content'>You can contact us with anything related to Keepe. <br /> We'll get in touch with you as soon as possible.</p>
                        <p className='section-content'>Please email us at <a href="mailto:support@keepe.com">support@keepe.com</a></p>
                    </div>
                    <div className="col-md-4 wow-container">
                        <div className="wow animate__animated animate__fadeInRight"
                            data-wow-duration="1s"
                            data-wow-delay="1s">
                            <div>
                                <h3 className='section-sub-heading'>Our Address</h3>
                                <p className='section-content'>901 5th Ave Ste 1950<br />Seattle, WA 98164</p>
                            </div>
                            <div>
                                <h3 className='section-sub-heading'>By Phone</h3>
                                <p className='section-content'>+1-866-947-6215</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Support;
