import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Services from "../../components/Services";
import Testimonials from "../../components/Testimonials";
import OurClient from "../../components/OurClient";
import Footer from "../../components/Footer";
const HomePage = () => {
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <Testimonials />
      <OurClient />
      <Footer />
    </>
  );
};
export default HomePage;
