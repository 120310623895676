import { TypeAnimation } from "react-type-animation";
const TypedAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        "Connect Your Portfolio of Homes to Our Network of Technicians",
        9500,
        "We Own Logistics and Fulfillment",
        6500,
        "Leverage Our Nationwide Network of Vetted Technicians",
        9500
      ]}
      wrapper="span"
      speed={5}
      repeat={Infinity}
      cursor={false}
      nonce="25a6c7f29c"
      className="heading-typed-flip"
      omitDeletionAnimation="false"
    />
  );
};
export default TypedAnimation;
