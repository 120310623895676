import React, { useState, useEffect } from "react";
import Parse from "parse";
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { loadImage } from "./Const";
import { useLocation } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const ModalContactKeepe = ({ modalIsOpen, closeModal }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = searchParams.get('utm_campaign');
  const initialFormData = {
    companyName: "",
    contactName: "",
    email: "",
    location: ""
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  useEffect(() => {
    setIsFormSubmitted(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate location field to allow only numbers and max 5 digits
    if (name === "location" && !/^\d{0,5}(-\d{0,4})?$/.test(value)) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value
    });

    // Clear error for the specific field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number) => {
    const phoneNumber = parsePhoneNumberFromString(number, 'US');
    return phoneNumber && phoneNumber.isValid();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.companyName) newErrors.companyName = "Company Name is required";
    if (!formData.contactName) newErrors.contactName = "Contact Name is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    else if (!validatePhoneNumber(phoneNumber)) newErrors.phoneNumber = "Phone Number is not valid";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!validateEmail(formData.email)) newErrors.email = "Email is not valid";
    if (!formData.location) newErrors.location = "Zip Code is required";
    else if (!/^\d{5}$/.test(formData.location)) newErrors.location = "Zip Code must be exactly 5 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsBtnLoading(true);
      const contactName = formData.contactName;
      const [firstName, ...lastNameParts] = contactName.split(" ");
      const lastName = lastNameParts.join(" ");

      const user = {
        company: formData.companyName,
        firstname: firstName,
        lastname: lastName || '',
        phone: phoneNumber,
        email: formData.email,
        postal: formData.location,
        type: ['homewarranty'],
        campaign: utmCampaign ? utmCampaign : 'home'
      };

      const userObject = { user };
      Parse.Cloud.run("createWaitlist", userObject).then((result) => {
        setIsFormSubmitted(true);
        setFormData(initialFormData);
        setPhoneNumber();
        setErrors({});
        setIsBtnLoading(false);

        setTimeout(() => {
          closeModal();
        }, 5000);
      }, (error) => {
        setIsBtnLoading(false);
      });
    }
  };

  return (
    <>
      <div
        className={`custom-modal contact-keepe-modal modal fade ${modalIsOpen ? "in show d-block" : ""}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body kh-white-box p-3">
              <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
                {isFormSubmitted ?
                  <div className="mt-4">
                    <div className="d-flex justify-content-center align-items-center justify-content-center">
                      <div className=""><img src={loadImage('thanks.png')} alt="Thank you" className="mr-3"  /></div>
                      <div className="thanks-heading">We will be in touch with you soon!</div>
                    </div>
                    <div className="thanks-block">
                      <button className="thanks-close-btn mt-3" type="button" onClick={closeModal}>Close</button>
                    </div>
                  </div> :
                  <>
                    <div className="lg-title">Contact Keepe</div>

                    <form className="mt-1" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 form-group mt-2">
                          <label className="fw-bold mb-1">Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            className="form-control"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                          />
                          {errors.companyName && (
                            <div className="text-danger">{errors.companyName}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group mt-2">
                          <label className="fw-bold mb-1">Contact Name</label>
                          <input
                            type="text"
                            name="contactName"
                            className="form-control"
                            placeholder="Contact Name"
                            value={formData.contactName}
                            onChange={handleChange}
                          />
                          {errors.contactName && (
                            <div className="text-danger">{errors.contactName}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group mt-2">
                          <label className="fw-bold mb-1">Phone Number</label>
                          <Input
                            country="US"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={setPhoneNumber} 
                            className="form-control"
                            />
                          {errors.phoneNumber && (
                            <div className="text-danger">{errors.phoneNumber}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group mt-2">
                          <label className="fw-bold mb-1">Email</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group mt-2">
                          <label className="fw-bold mb-1">Location</label>
                          <input
                            type="text"
                            name="location"
                            className="form-control"
                            placeholder="Zip Code"
                            value={formData.location}
                            onChange={handleChange}
                          />
                          {errors.location && (
                            <div className="text-danger">{errors.location}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group mt-2 mb-2">
                          <label className="fw-bold mb-1">&nbsp;</label>
                          <br />
                          <button className="theme-btn bradius-4 w-100" type="submit" disabled={isBtnLoading}>
                            Submit
                            {isBtnLoading && 
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${modalIsOpen ? "show d-block" : "d-none"}`}
      ></div>
    </>
  );
};
