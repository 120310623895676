import React,{useEffect} from "react";
import { loadImage } from "../Const";
import { initializeWow, cleanupWow } from '../Const';

const ContactUsHeightlight = () => {
    useEffect(() => {
        initializeWow();
        return () => {
          cleanupWow();
        };
      }, [])
    return (
        <>
            <div className="container-fluid bg-dark-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row  p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1s"
                            data-wow-delay="0.5s"
                        >
                            <img
                                src={loadImage('service-img-1.png')}
                                alt="service-img-1.png"
                            />
                        </div>
                        <div className="wow-container">
                        <div className="wow animate__animated animate__fadeInRight"
                            data-wow-duration="1s"
                            data-wow-delay="0.5">
                            <h2 className="text-white">Jobs. Not Leads</h2>
                            <p className="text-white">
                                Keepe sends you pre-estimated jobs instead of leads. There is no wasted time on providing estimates and no following up on unqualified leads. You get access to actual jobs that need to be done right away.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <h2 className="text-white">No Upfront Costs</h2>
                            <p className="text-white">
                                With Keepe, there are no upfront costs or fees. Keepe takes care of all payments from the customer. You do not have to worry about credit card fees. Payments are guaranteed to you for successfully completed jobs.
                            </p>
                        </div>
                        <div className="wow animate__animated animate__fadeInRight"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('service-img-2.png')}
                                alt="service-img-2.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('service-img-3.png')}
                                alt="service-img-3.png"
                            />
                        </div>
                        <div className="wow-container">
                        <div className="wow animate__animated animate__fadeInRight"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <h2 className="text-white">Set Your Own Schedule</h2>
                            <p className="text-white">
                                Be the master of your own schedule by setting your availability in the app. If you are working on jobs for your own clients, set your availability to off. When you have down time, set your availability to on and jobs start coming to you.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1.5s">
                            <h2 className="text-white">All Contractors Welcome</h2>
                            <p className="text-white">
                                Augment your income by getting the Keeper App. If you are a licensed handyman, plumber, electrician, carpenter, roofer, landscaper, painter, concrete guy, flooring guy, appliances guy or other contractor with specific skills, become a part of the Keeper network.
                            </p>
                        </div>
                        <div className="wow animate__animated animate__fadeInRight"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('service-img-4.png')}
                                alt="service-img-4.png"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default ContactUsHeightlight;
