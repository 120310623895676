import React, { useEffect } from 'react';
import ContactUsForm from '../../components/ContactUs/ContactUsForm';
import ContactUsHeightlight from '../../components/ContactUs/ContactUsHeightlight';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useSearchParams, useLocation } from 'react-router-dom';

const ContactUs = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const bgImageBasedOnType = {
    ho: 'bg-image-ho',
    hw: 'bg-image-hw',
    tech: 'bg-image-tech',
    pm: 'bg-image-pm',
  };

  const bgClassName = bgImageBasedOnType[type] || 'bg-image-tech';

  return (
    <>
      <Header isAddbg={true} />
      <div style={{ backgroundColor: `${type === 'ho' ?'#D0CFCB' :'#D5F0F1'}` }}>
        <div className={`${bgClassName}`}>
          <div className={`${type === 'ho' || type === 'hw' || type === 'pm'  ? 'form-position-ho' : 'form-position'} wow animate__animated animate__zoomIn`}
            data-wow-duration="1s"
            data-wow-delay="1s">
            <ContactUsForm type={type} />
          </div>
        </div>
      </div>
      {(type !== 'pm' && type !== 'hw') &&
        <ContactUsHeightlight />}

      <div className={`${type === 'pm' || type === 'hw' ? 'footer-position' : ''}`}>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
