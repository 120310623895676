import React, { forwardRef, useEffect, useState } from "react";
import { loadImage } from "./Const";
import { initializeWow, cleanupWow } from './Const';
import { ModalContactKeepe } from "./ModalContactKeepe";

const Heighlight = forwardRef((props, ref) => {

  useEffect(() => {
    initializeWow();
    return () => {
      cleanupWow();
    };
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section ref={ref} id="nextSection" className="overflow-hidden">
        <section className="info-wrap py-10">
          <div className="container">
            <div className="row align-items-center gx-5">
              <div
                className="col-md-6 wow animate__animated animate__zoomIn"
                data-wow-duration="1.5s"
                data-wow-delay="0s"
              >
                <div className="white-box p-0">
                  <img className="w-100" src={loadImage("nationwide-coverage.png")} alt="icon" />
                </div>
              </div>
              <div
                className="col-md-6 hi-content"
              >
                <div className="hi-title mb-3">
                  Nationwide Network of Technicians
                </div>
                <p>
                  Over 100K work orders have been performed on Keepe’s Fulfillment Platform. Keepe’s nationwide network of vetted technicians are insured and background-checked.<br />
                  <span onClick={() => handleShow()} className="learn-more">Learn More...</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="info-wrap py-10">
          <div className="container">
            <div className="row align-items-center gx-5">
              <div
                className="col-md-6 wow animate__animated animate__zoomIn order-2"
                data-wow-duration="1.5s"
                data-wow-delay="0s"
              >
                <div className="white-box p-0">
                  <img className="w-100" src={loadImage("diagnose-free.png")} alt="icon" />
                </div>
              </div>
              <div
                className="col-md-6 hi-content"
              >
                <div className="hi-title mb-3">
                  All the Trades
                </div>
                <p>
                  Keepe’s platform handles HVAC, Plumbing, Electrical, Major Appliances, EV Charging stations and several other trades with qualified technicians who you can trust.<br /> <span onClick={handleShow} className="learn-more">Learn More...</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="info-wrap py-10">
          <div className="container">
            <div className="row align-items-center gx-5">
              <div
                className="col-md-6 wow animate__animated animate__zoomIn"
                data-wow-duration="1.5s"
                data-wow-delay="0s"
              >
                <div className="white-box p-0">
                  <img className="w-100" src={loadImage("Accelerate-AI.jpg")} alt="icon" />
                </div>
              </div>
              <div
                className="col-md-6 hi-content"
              >
                <div className="hi-title mb-3">
                  Accelerate Your Corporate Processes with AI
                </div>
                <p>
                  Keepe’s technology platform adds state-of-the-art enterprise grade artificial intelligence capabilities to existing processes such as AI Diagnosis, Predictive Authorization and many others.<br /><span onClick={handleShow} className="learn-more">Learn More...</span>

                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="info-wrap py-10">
          <div className="container">
            <div className="row align-items-center gx-5">
              <div
                className="col-md-6 wow animate__animated animate__zoomIn order-2"
                data-wow-duration="1.5s"
                data-wow-delay="0s"
              >
                <div className="white-box p-0">
                  <img className="w-100" src={loadImage("customer-experience.jpg")} alt="icon" />
                </div>
              </div>
              <div
                className="col-md-6 hi-content"
              >
                <div className="hi-title mb-3">
                  Enable a Better Customer Experience
                </div>
                <p>
                  Keepe delivers a superior experience to your customers without you having to build a first-party fleet of technicians for every location that you serve. <br />              <span onClick={handleShow} className="learn-more">Learn More...</span>

                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <ModalContactKeepe
       modalIsOpen={show}
       closeModal={handleClose}
      />
    </>

  );
});
export default Heighlight;
