import React, { useState } from "react";
import { loadImage } from "./Const";
import { Link } from "react-router-dom";
import config from "../config/index";

export const Header = ({ isAddbg ,resident }) => {
  const keepeAIUrl = config.externallink.keepeAi;
  let url = new URL(keepeAIUrl);
  let baseUrl = `${url.protocol}//${url.hostname}`;

  const [menuOpen, setMenuOpen] = useState(false);
  const activeMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <>
      <header className={`${isAddbg ? 'header-bg header-wrap' : (resident ? 'header-wrap-resident' : 'header-wrap-home')}`}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="main-logo">
            <Link to="/">
              <img src={loadImage("keepe.png")} alt="keepe" />
              </Link>
            </div>

            <div className="d-flex align-items-center mnw-flex">
              <div className={`main-menu ${menuOpen ? "active" : ""}`}>
                <div className="main-logo d-lg-none">
                <Link to="/">
                  <img src={loadImage("keepe.png")} alt="keepe" />
                  </Link>
                </div>
                <ul className="d-flex align-items-center">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle dropdown-enterprise"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Not An Enterprise?
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link onClick={activeMenu} className="dropdown-item" to="/contactus?type=tech&utm_source=home&utm_medium=keepe-header&utm_term=home&utm_content=home&utm_campaign=manager-landing-home">I'm a Contractor</Link>
                      <Link onClick={activeMenu} className="dropdown-item" to="/residents" >I'm a Landlord</Link>
                      <Link onClick={activeMenu} className="dropdown-item" to="/contactus?type=pm&utm_source=home&utm_medium=keepe-header&utm_term=home&utm_content=home&utm_campaign=manager-landing-home">I'm a Property Manager</Link>
                    </div>
                  </div>

                  <li>
                    <Link onClick={activeMenu} to="/contactus?type=tech&utm_source=home&utm_medium=keepe-header&utm_term=home&utm_content=home&utm_campaign=manager-landing-home">Join our Pro Network</Link>

                  </li>
                  <li onClick={activeMenu} className="get-started">
                  <a target="_blank"
                      rel="noopener noreferrer" href={baseUrl}>
                      DIY Project
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ms-3">
                <button
                  className={`mobile-inner-header-icon ${menuOpen
                    ? "mobile-inner-header-icon-click"
                    : "mobile-inner-header-icon-out"
                    }`}
                  type="button"
                  onClick={activeMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
