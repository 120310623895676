import React, { useState, useEffect } from 'react';
import Parse from "parse";
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import { useLocation } from 'react-router-dom';
import { loadImage } from "../Const";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const ContactUsForm = ({ type }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    /* eslint-disable react-hooks/exhaustive-deps */
    const commonFields = {
        email: '',
        zipCode: ''
    };

    const [tradeList, setTradeList] = useState([]);
    const [selectedTrades, setSelectedTrades] = useState([]);
    const [errors, setErrors] = useState({});
    const [isFormSubmmited, setisFormSubmmited] = useState(false);
    const [phoneNumber, setphoneNumber] = useState();
    const [formData, setFormData] = useState({ ...commonFields });
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {
        Parse.Cloud.run('getKeeperTrades', {}).then(data => {
            setTradeList(data.map(trade => ({ value: trade.code, label: trade.trade })));
        });
        setisFormSubmmited(false);
    }, []);

    useEffect(() => {
        // Reset form data and other states when type changes
        const additionalFieldsByType = {
            ho: { name: '' },
            hw: { companyName: '', contactName: '' },
            tech: { name: '', trade: '' },
            pm: { companyName: '', contactName: '', numberOfProperties: '' }
        };

        const initialFormData = { ...commonFields, ...additionalFieldsByType[type] };
        setFormData(initialFormData);
        setSelectedTrades([]);
        setErrors({});
        setphoneNumber();
        setisFormSubmmited(false);
    }, [type]);



    const determineUserType = (type) => {
        switch (type) {
            case 'pm':
                return 'propertymgr';
            case 'tech':
                return 'pro';
            case 'ho':
                return 'consumer';
            case 'hw':
                return 'homewarranty';
            default:
                return type;
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

        // Clear error for the specific field being changed
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: ''
        }));
    };

    const handleTradesChange = (selected) => {
        setSelectedTrades(selected);

        // Clear trade errors when trades are selected
        if (type === 'tech') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                trades: ''
            }));
        }
    };

    const handlePhoneChange = (value) => {
        setphoneNumber(value);

        // Clear phone number error when phone number is entered
        setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber: ''
        }));
    };

    const validatePhoneNumber = (number) => {
        const phoneNumber = parsePhoneNumberFromString(number, 'US');
        return phoneNumber && phoneNumber.isValid();
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                switch (key) {
                    case 'name':
                        newErrors[key] = 'Name is required';
                        break;
                    case 'companyName':
                        newErrors[key] = 'Company Name is required';
                        break;
                    case 'contactName':
                        newErrors[key] = 'Contact Name is required';
                        break;
                    case 'numberOfProperties':
                        newErrors[key] = 'Number of Properties is required';
                        break;
                    case 'email':
                        newErrors[key] = 'Email is required';
                        break;
                    case 'zipCode':
                        newErrors[key] = 'Zip Code is required';
                        break;
                    default:
                        break;
                }
            }
        });

        if (type === 'tech' && selectedTrades.length === 0) {
            newErrors.trades = 'At least one trade must be selected';
        }
        if (phoneNumber === undefined) {
            newErrors.phoneNumber = 'Phone Number is required';
        } else if (!validatePhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = 'Phone Number is not valid';
        }

        if (formData.zipCode && !/^\d{5}$/.test(formData.zipCode)) {
            newErrors.zipCode = 'Zip Code must be exactly 5 digits';
        }
        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Email is not valid';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setIsBtnLoading(true);
        const user = {
            phone: phoneNumber,
            email: formData.email,
            postal: formData.zipCode,
            type: [determineUserType(type)],
            campaign: utmCampaign
        };
        const contactName = formData.name ? formData.name : formData.contactName;
        const [firstName, ...lastNameParts] = contactName.split(" ");
        const lastName = lastNameParts.join(" ");

        switch (type) {
            case 'ho':
                user.firstname = firstName;
                user.lastname = lastName || '';
                break;
            case 'hw':
                user.company = formData.companyName;
                user.firstname = firstName;
                user.lastname = lastName || '';
                break;
            case 'tech':
                user.firstname = firstName;
                user.lastname = lastName || '';
                user.trades = selectedTrades ? selectedTrades.map(trade => trade.label).join(", ") : "";
                break;
            case 'pm':
                user.company = formData.companyName;
                user.firstname = firstName;
                user.lastname = lastName || '';
                user.noProperties = Number(formData.numberOfProperties);
                break;
            default:
                break;
        }
        const userObject = { user };
        Parse.Cloud.run("createWaitlist", userObject).then((result) => {
            // Handle success
        }, (error) => {
            // Handle error
        });
        setisFormSubmmited(true);
        setIsBtnLoading(false);
    };

    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left'
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '40px'
        }),
    };

    const renderAdditionalFields = () => {
        switch (type) {
            case 'ho':
            case 'tech':
                return (
                    <>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control bg-dark-50 border-0"
                                id="name"
                                placeholder="Name"
                                value={formData.name || ''}
                                onChange={handleInputChange}
                            />
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                        </div>
                        {type === 'tech' && (
                            <div className="mb-3">
                                <Select
                                    options={tradeList}
                                    isMulti
                                    isClearable
                                    placeholder="Select Trades"
                                    value={selectedTrades}
                                    onChange={handleTradesChange}
                                    styles={customStyles}
                                />
                                {errors.trades && <div className="text-danger">{errors.trades}</div>}
                            </div>
                        )}
                    </>
                );
            case 'hw':
                return (
                    <>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control bg-dark-50 border-0"
                                id="companyName"
                                placeholder="Company Name"
                                value={formData.companyName || ''}
                                onChange={handleInputChange}
                            />
                            {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control bg-dark-50 border-0"
                                id="contactName"
                                placeholder="Contact Name"
                                value={formData.contactName || ''}
                                onChange={handleInputChange}
                            />
                            {errors.contactName && <div className="text-danger">{errors.contactName}</div>}
                        </div>
                    </>
                );
            case 'pm':
                return (
                    <>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control bg-dark-50 border-0"
                                id="companyName"
                                placeholder="Company Name"
                                value={formData.companyName || ''}
                                onChange={handleInputChange}
                            />
                            {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control bg-dark-50 border-0"
                                id="contactName"
                                placeholder="Contact Name"
                                value={formData.contactName || ''}
                                onChange={handleInputChange}
                            />
                            {errors.contactName && <div className="text-danger">{errors.contactName}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="number"
                                className="form-control bg-dark-50 border-0"
                                id="numberOfProperties"
                                placeholder="Number of Properties"
                                value={formData.numberOfProperties || ''}
                                onChange={handleInputChange}
                            />
                            {errors.numberOfProperties && <div className="text-danger">{errors.numberOfProperties}</div>}
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="card ms-auto bg-info border-0 rounded-circle" style={{ width: "25rem" }}>
                <div className="card-body outer-form-container">
                    <h2 className="card-title text-white">{type === 'pm' ? 'Get Your Jobs Done' : 'Work Contractor Jobs On Your Own Schedule'}</h2>
                    <div className="text-white">
                        <ul className='form-heightlight'>
                            <li className='li'>{type === 'pm' ? 'Connect Your Portfolio of Homes to Our Network of Technicians' : 'Get Jobs, Not Leads'}</li>
                            <li className='li'>{type === 'pm' ? 'We Own Logistics and Fulfillment' : 'No Bidding. No Upfront Fees'}</li>
                            <li className='li'>{type === 'pm' ? 'Leverage Our Nationwide Network of Vetted Technicians' : 'Guaranteed Payments for Completed Jobs'}</li>
                        </ul>
                    </div>
                    {isFormSubmmited ?
                        <div className='mb-4 inner-form-container1'>
                            <div className="d-flex justify-content-center align-items-center justify-content-center">
                                <div className=""><img src={loadImage('thanks.png')} alt="Thank you" className="mr-3" /></div>
                                <div className="thanks">We will be in touch with you soon!</div>
                            </div>
                        </div> :
                        <form className="mb-4 inner-form-container1" onSubmit={handleSubmit}>
                            {renderAdditionalFields()}
                            <div className="mb-3">
                                <Input
                                    country="US"
                                    placeholder="Enter Phone Number"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    className="form-control bg-dark-50 border-0 PhoneInputInput"
                                />
                                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control bg-dark-50 border-0"
                                    id="email"
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control bg-dark-50 border-0"
                                    id="zipCode"
                                    placeholder="Location"
                                    value={formData.zipCode}
                                    onChange={handleInputChange}
                                />
                                {errors.zipCode && <div className="text-danger">{errors.zipCode}</div>}
                            </div>
                            <button type="submit" className="btn btn-warning" disabled={isBtnLoading}>
                                Submit
                                {isBtnLoading &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </button>
                        </form>
                    }
                </div>
            </div>
        </>
    );
};

export default ContactUsForm;
