import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useSearchParams } from 'react-router-dom';
import { loadImage } from "../../components/Const";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { initializeWow, cleanupWow } from '../../components/Const';

const FounderData = [
    {
        src: loadImage('rishi.png'),
        name: 'Rishi Mathew',
        title: 'Founder, CEO'
    },
    {
        src: loadImage('vishal.jpg'),
        name: 'Vishal Srivastava',
        title: 'Founder, CTO'
    },
    {
        src: loadImage('armand.png'),
        name: 'Armand Pizzicarola',
        title: 'VP Operations'
    },
    {
        src: loadImage('brian.jpg'),
        name: 'Brian Hintz',
        title: 'SVP Business Development & Partnerships'
    },
];

const SliderData = [
    { src: loadImage('office0.jpg'), alt: 'Office 0' },
    { src: loadImage('office3.jpg'), alt: 'Office 1' },
    { src: loadImage('office5.jpg'), alt: 'Office 5' },
    { src: loadImage('office1.jpg'), alt: 'Office 1' },
    { src: loadImage('office4.jpg'), alt: 'Office 4' },
    { src: loadImage('office6.jpg'), alt: 'Office 6' },
    { src: loadImage('office2.jpg'), alt: 'Office 2' },
    { src: loadImage('office7.jpg'), alt: 'Office 7' }
]

const AboutUs = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [searchParams]);


    useEffect(() => {
        initializeWow();
        return () => {
            cleanupWow();
        };
    }, []);
    return (
        <>
            <Header isAddbg={true} />
            <div className='about-section mt-4'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className='section-heading'>Our Story</h3>
                            <p className='section-content'>
                                Keepe connects homeowners with qualified maintenance
                                professionals in order to make home repairs easier.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" interval="4000">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 5"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 6"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 5"></button>
                                </div>
                                <div className="carousel-inner">
                                    {SliderData.map((image, index) => (
                                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                            <img src={image.src} className="d-block w-100" alt={image.alt} />
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-5"
                        data-wow-duration="1s"
                        data-wow-delay="1s">
                        <div className="col-md-12">
                            <h3 className='section-heading'>Our Mission</h3>
                        </div>
                    </div>
                    <div className="row"
                        data-wow-duration="1s"
                        data-wow-delay="1s">
                        <div className="col-md-12">
                            <p className='section-content'>
                                We created the company with the ambitious goal of
                                transforming the experience of living in a home by
                                modernizing the process of getting someone to come
                                to your home for repairs. The Home Services market
                                today is too slow, too painful and too opaque for
                                everyone involved.
                            </p>
                        </div>
                    </div>
                    <div className="row"
                        data-wow-duration="1s"
                        data-wow-delay="1.5s">
                        <div className="col-md-12">
                            <p className='section-content'>
                                We aim to create technology tools that deliver fast
                                turnaround and optimal quality at reasonable cost.
                                In the process, we want to make renting, landlording
                                and managing properties way easier than it is today.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 text-center">
                        <div className="col-sm-4 wow animate__animated animate__zoomIn"
                            data-wow-duration="1.5s"
                            data-wow-delay="1.3s">
                            <strong className='section-heading'>2000+</strong>
                            <p className='section-content'>
                                Contractors
                            </p>

                        </div>
                        <div className="col-sm-4 wow animate__animated animate__zoomIn"
                            data-wow-duration="1.5s"
                            data-wow-delay="2s">
                            <strong className='section-heading'>100,000+</strong>
                            <p className='section-content'>
                                Projects
                            </p>

                        </div>

                        <div className="col-sm-4 wow animate__animated animate__zoomIn"
                            data-wow-duration="1.5s"
                            data-wow-delay="0.5s">
                            <strong className='section-heading'>Many</strong>
                            <p className='section-content'>
                                Happy Customers
                            </p>

                        </div>
                    </div>
                    <div className="d-flex flex-wrap gap-3 justify-content-center mt-5 founder-info wow animate__animated animate__zoomIn"
                        data-wow-duration="1.5s"
                        data-wow-delay="1.3s">
                        {FounderData.map((item, index) => (
                            <div key={index}>
                                <img
                                    src={item.src}
                                    alt={item.name}
                                    data-tooltip-id={`tooltip-${index}`}
                                    data-tooltip-html={`<div style="text-align: center;"><strong>${item.name}</strong><br>${item.title}</div>`}
                                />
                                <ReactTooltip id={`tooltip-${index}`} style={{
                                    backgroundColor: '#36414E',
                                    color: '#fff'
                                }} />
                            </div>
                        ))}

                    </div>

                </div>
                <div className='container-fluid'>
                    <div className="row mt-5 text-center"
                    >
                        <div className="col-md-12 team-message"
                        >
                            <h4 className='wow animate__animated animate__zoomIn'
                                data-wow-duration="1s"
                                data-wow-delay="1s">
                                The Keepe team is expanding quickly. If you are <br />
                                collaborative, hardworking and fun - please join us!
                            </h4>
                            <a href='https://wellfound.com/company/keepe/jobs'
                             >
                                <button className='wow animate__animated animate__zoomIn'
                                 data-wow-duration="1s"
                                 data-wow-delay="1.5s">
                                    Join our team
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
