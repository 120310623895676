import React, { forwardRef, useEffect } from "react";
import { initializeWow, cleanupWow, loadImage } from '../../components/Const';

const ResidentsHeightlight = forwardRef((props, ref) => {
    useEffect(() => {
        initializeWow();
        return () => {
            cleanupWow();
        };
    }, []);
    return (
        <>
            <div className="container-fluid resident-bg-fade-blue">
                <div className="container text-center">
                    <div className="row text-white pt-4 gap-5 wow animate__animated animate__zoomIn"
                        data-wow-duration="1s"
                        data-wow-delay="1s">
                        <div className="col-sm">
                            <h4>DIY or Leave it to the Pros
                            </h4>
                            <p className="text-white">Forget about planning ahead! Keepe delivers the right contractor at your doorstep after the job is accepted</p>
                        </div>
                        <div className="col-sm">
                            <h4>Available 7 Days a Week

                            </h4>
                            <p className="text-white">Technicians are available on evenings and weekends, so you can request service at your convenience

                            </p>
                        </div>
                        <div className="col-sm">
                            <h4>Reliable Contractors

                            </h4>
                            <p className="text-white">Besides being licensed, bonded and insured, each Keeper is thoroughly background checked

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <div className="container-fluid resident-how-it-work-bg resident-how-it-work-section">
                <div className="container text-center  p-5">
                    <h2>How it Works
                    </h2>

                    <div className="row text-white gap-5 mt-5">
                        <div className="col-sm">
                            <p>Take a Picture and Enter Description <br />
                                of the Problem</p>
                            <img className="mt-4 wow animate__animated animate__fadeIn" data-wow-duration="1s"
                                data-wow-delay="1s" src={loadImage('residents-app-img-1.jpg')} alt="residents-app-img-1" />
                        </div>
                        <div className="col-sm">
                            <p>Request A Keeper <br /></p>
                            <img className="mt-4 wow animate__animated animate__fadeIn" data-wow-duration="1s"
                                data-wow-delay="1s" src={loadImage('residents-app-img-2.jpg')} alt="residents-app-img-2" />
                        </div>
                        <div className="col-sm">
                            <p>The Best Available Keeper
                                arrives same day.</p>
                            <img className="mt-4 wow animate__animated animate__fadeIn" data-wow-duration="1s"
                                data-wow-delay="1s" src={loadImage('residents-app-img-3.jpg')} alt="residents-app-img-3" />
                        </div>
                    </div>
                </div>
            </div>
            */}
            <div className="container-fluid resident-bg-dark-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <h2 className="text-white">DIY or Leave it to the Pros</h2>
                            <p className="text-white">
                                Forget about planning ahead! Keepe delivers the right contractor at your doorstep minutes after the job is accepted. You don’t need to talk to anybody if you don’t want to. Take a Picture of your problem and submit request. You get a response immediately.
                            </p>
                        </div>
                        <div className="wow animate__animated animate__rotateInDownRight"
                            data-wow-duration="1.5s"
                            data-wow-delay="1.5s">
                            <img
                                src={loadImage('residents-service-img-3.png')}
                                alt="residents-service-img-3.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid resident-bg-light-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__rotateInDownLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('residents-service-img-4.png')}
                                alt="residents-service-img-4"
                            />
                        </div>
                        <div className="wow-container">
                            <div className="wow animate__animated animate__fadeInRight"
                                data-wow-duration="1.5s"
                                data-wow-delay="1s">
                                <h2 className="text-white">Free Price Estimates</h2>
                                <p className="text-white">
                                    Keepe provides clarity in how each job is priced, so you can request service with confidence. Free Price Estimates are available before you submit a request. We will charge your credit card on file and email a receipt only after the job is completed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid resident-bg-dark-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__fadeInLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <h2 className="text-white">No Markup on Materials</h2>
                            <p className="text-white">
                                It is best if you have all materials and parts ready for the job. If not, your Keeper will happily go to the nearest department store and get the right materials for the job. There is no markup on materials, so you pay what you would have if you bought it at the store yourself.
                            </p>
                        </div>
                        <div className="wow animate__animated animate__rotateInDownRight"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('residents-service-img-2.png')}
                                alt="residents-service-img-3.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid resident-bg-light-blue">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row p-5 justify-content-between align-items-center gap-5">
                        <div className="wow animate__animated animate__rotateInDownLeft"
                            data-wow-duration="1.5s"
                            data-wow-delay="1s">
                            <img
                                src={loadImage('residents-service-img-1.png')}
                                alt="residents-service-img-1"
                            />
                        </div>
                        <div className="wow-container">
                            <div className="wow animate__animated animate__fadeInRight"
                                data-wow-duration="1.5s"
                                data-wow-delay="1s">
                                <h2 className="text-white">Reliable Contractors</h2>
                                <p className="text-white">
                                    Besides being licensed, bonded and insured, each Keeper is thoroughly background checked. After each completed job, the contractor is rated. We only send the highest-rated contractors to a new job, so you are always getting the best available. The Keeper’s intention is always to make you happy and complete the job.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
});

export default ResidentsHeightlight;
