import React, { useEffect } from 'react';
import Header from '../../components/Header'
import Testimonials from '../../components/Testimonials'
import Footer from '../../components/Footer'
import ResidentsHeightlight from './ResidentsHeightlight'
import { useLocation } from 'react-router-dom';
import config from "../../config/index";

const Residents = () => {
    const { pathname } = useLocation();
    const keepeAIUrl = config.externallink.keepeAi;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header resident={true }/>
            <div className={`bg-image-resident`}>
                <div className='text-position-resident'>
                    <div className='resident-banner'>
                        <h2 className='wow animate__animated animate__zoomIn'
                            data-wow-duration="1s"
                            data-wow-delay="1s">An End to Honey-do Lists</h2>
                        <h3 className='wow animate__animated animate__zoomIn'
                            data-wow-duration="1.5s"
                            data-wow-delay="1.5s">Get a Technician to your House the same day</h3>
                        <a target="_blank"
                            rel="noopener noreferrer" href={keepeAIUrl}>
                            <button
                                className="theme-btn gradient-btn sm-btn wow animate__animated animate__zoomIn mt-4"
                                data-wow-duration="2s"
                                data-wow-delay="2s"
                                type="button"
                            >
                                Start Diagnosis
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <ResidentsHeightlight />
            <Testimonials />
            <Footer />
        </>
    )
}

export default Residents
